import {
  postLogInteraction,
  postLogPageView,
  postLogPageViewRum,
  transformLogPageViewRum
} from "~apollo/graphql-gateway/logMutationsActions";
import {
  AMPLIFY_CURRENT_RESUME_ID,
  AMPLIFY_RBK,
  AMPLIFY_TEST_NAME,
  CURRENT_SESSION,
  LOG_PAGE_VIEWS,
  LOG_PAGE_VIEWS_RUM,
  PREVIOUS_PATH,
  SESSION_COUNT,
  SESSION_ID,
  SESSION_LANDING_PAGE,
  SESSION_LAST_INTERACTION,
  SESSION_START_TIME
} from "~lib/keyNamesOfLocalStorage";
import { getLocalUserObject, hasLocalStorage } from "~lib/localStorage";

const getStorageItem = (item: string): any => {
  return JSON.parse(localStorage.getItem(item || "")) || {};
};

function buildRumEndpoint(graphqlEndpoint: string): string {
  let root: string;
  if (graphqlEndpoint.includes("graph")) {
    root = graphqlEndpoint.substring(0, graphqlEndpoint.lastIndexOf("/graph"));
  } else {
    root = graphqlEndpoint.substring(0, graphqlEndpoint.lastIndexOf("/api"));
  }

  return root + "/LogPageViewRum";
}

function isBot(agent: string): boolean {
  const bots = ["bot", "spider", "zbrab", "com", "craw", "scan"];
  for (const bot of bots) {
    if (agent.includes(bot)) {
      return true;
    }
  }
  return false;
}

function findMatch(string: string, array: string[]): boolean {
  for (let i = 0; i < array.length; i++) {
    if (string.includes(array[i])) return true;
  }
  return false;
}

function getRefType(referer: string): string {
  if (window.location.href.indexOf("gclid") > -1) {
    return "sem";
  } else if (!referer) {
    return "direct";
  } else if (findMatch(referer, ["google", "bing", "yahoo"])) {
    return "organic";
  }
  return "referral";
}

// LOGGING REFERRALS FROM JOB MARKET CAMPAIGN
if (hasLocalStorage()) {
  const jobmarketTestName = /\?from=job-market/.test(window.location.href)
    ? "indeed_job_market"
    : undefined;
  if (jobmarketTestName) {
    // get existing test name, if any. If not, set to current test
    const existingTestNames = JSON.parse(
      localStorage.getItem(AMPLIFY_TEST_NAME)
    );
    if (!existingTestNames || !existingTestNames.data) {
      localStorage.setItem(
        AMPLIFY_TEST_NAME,
        JSON.stringify({
          data: jobmarketTestName,
          expires: null
        })
      );
    } else {
      const existingTest = existingTestNames.data.split(","); // get existing test name(s) as a comma separated list

      // if current test name not already saved
      // add new test name to existing names, separated by comma
      if (existingTest.indexOf(jobmarketTestName) < 0) {
        existingTest.push(jobmarketTestName);
      }

      // save the new list of test names
      existingTestNames.data = existingTest.join(",");
      localStorage.setItem(
        AMPLIFY_TEST_NAME,
        JSON.stringify(existingTestNames)
      );
    }
  }
}

const __testName =
  hasLocalStorage() && localStorage.getItem(AMPLIFY_TEST_NAME)
    ? getStorageItem(AMPLIFY_TEST_NAME)["data"]
    : "undefined";

export async function init(): Promise<void> {
  if (process.env.NODE_ENV === "development") {
    console.log("Logging page view", window.location.href);
  }
  let __rbk = null;
  if (hasLocalStorage()) {
    __rbk = JSON.parse(localStorage.getItem(AMPLIFY_RBK));
  }
  const visitInfo: any = {
    pageVisitId: makeId(11),
    path: window.location.pathname,
    url: window.location.href,
    previousPath: updatePreviousPath()
  };
  const startTime = Date.now();

  if (!__rbk) {
    __rbk = { data: makeId(), expires: null };
    visitInfo.rbk = __rbk.data;
    if (hasLocalStorage()) {
      localStorage.setItem(AMPLIFY_RBK, JSON.stringify(__rbk));
    }
  }

  // update session
  updateSession();
  if (hasLocalStorage()) {
    visitInfo.sessionId = localStorage.getItem(SESSION_ID);
    visitInfo.sessionStartTime = localStorage.getItem(SESSION_START_TIME);
    visitInfo.sessionLastInteraction = localStorage.getItem(
      SESSION_LAST_INTERACTION
    );
    visitInfo.sessionCount = localStorage.getItem(SESSION_COUNT);
    visitInfo.sessionLandingPage = localStorage.getItem(SESSION_LANDING_PAGE);
  }
  visitInfo.rbk = __rbk.data;

  visitInfo.userId = getLocalUserObject()?.id;
  visitInfo.isClientSideNavigation = 0;
  visitInfo.referer = document.referrer;
  visitInfo.viewPortWidth = window.visualViewport
    ? window.visualViewport.width
    : window.innerWidth
    ? window.innerWidth
    : 0;
  visitInfo.viewPortHeight = window.visualViewport
    ? window.visualViewport.height
    : window.innerHeight
    ? window.innerHeight
    : 0;
  visitInfo.agent = navigator.userAgent;

  visitInfo.requestMethod = "GET";
  visitInfo.httpVersion = "1.1";
  visitInfo.protocol = "https";
  visitInfo.responseCode = 200;
  visitInfo.site = "resumecom";
  visitInfo.referer = document.referrer;
  visitInfo.reftype = getRefType(visitInfo.referer);

  if (visitInfo.agent) {
    visitInfo.bot = isBot(visitInfo.agent);
  }

  const robotsMetaElement = document.querySelector(
    "meta[name='ROBOTS']"
  ) as HTMLMetaElement;
  const isNoIndex = robotsMetaElement?.content.includes("noindex") ? 1 : 0;
  const isNoFollow = robotsMetaElement?.content.includes("nofollow") ? 1 : 0;
  const refCanonical = "";

  visitInfo.isNoFollow = isNoFollow;
  visitInfo.isNoIndex = isNoIndex;
  visitInfo.refCanonical = refCanonical;
  visitInfo.testName = __testName;

  const logPageviews = localStorage.getItem(LOG_PAGE_VIEWS);
  let visitInfoArray = null;
  if (
    logPageviews === null ||
    logPageviews === "null" ||
    logPageviews === "undefined" ||
    typeof logPageviews === "undefined"
  ) {
    visitInfoArray = [];
  } else {
    visitInfoArray = JSON.parse(logPageviews);
  }

  if (navigator === null || navigator.onLine === null || navigator.onLine) {
    visitInfo.online = true;
    visitInfoArray.push(visitInfo);
    try {
      await postLogPageView(visitInfo);
    } catch (e) {
      console.log(e);
      if (window && typeof window.Sentry === `object`) {
        window.Sentry.captureException(e);
      }
    }
    if (hasLocalStorage()) {
      localStorage.removeItem(LOG_PAGE_VIEWS);
    }
  } else {
    visitInfo.online = false;
    visitInfoArray.push(visitInfo);
    if (hasLocalStorage()) {
      localStorage.setItem(LOG_PAGE_VIEWS, JSON.stringify(visitInfoArray));
    }
  }

  if (!document["interactionListener"]) {
    document.addEventListener("click", updateSessionAndLog, false);
    document.addEventListener("scroll", updateSession, false);
    document.addEventListener("keypress", updateSessionAndLog, false);
    document["interactionListener"] = {};
    document["interactionListener"]["click"] = true;
    document["interactionListener"]["scroll"] = true;
    document["interactionListener"]["keypress"] = true;
  } else {
    if (!document["interactionListener"]["click"]) {
      document.addEventListener("click", updateSessionAndLog, false);
      document["interactionListener"]["click"] = true;
    }
    if (!document["interactionListener"]["scroll"]) {
      document.addEventListener("scroll", updateSession, false);
      document["interactionListener"]["scroll"] = true;
    }
    if (!document["interactionListener"]["keypress"]) {
      document.addEventListener("keypress", updateSessionAndLog, false);
      document["interactionListener"]["keypress"] = true;
    }
  }

  async function logPageViewFn() {
    const rumInfo: any = {
      rbk: visitInfo.rbk,
      userId: visitInfo.userId,
      pageVisitId: visitInfo.pageVisitId,
      path: visitInfo.path
    };
    // rumInfo.dwellTime = parseInt((Date.now() - startTime) / 1000);
    rumInfo.dwellTime = Date.now() - startTime;
    rumInfo.sessionId = localStorage.getItem(SESSION_ID);
    rumInfo.sessionStartTime = localStorage.getItem(SESSION_START_TIME);
    rumInfo.sessionLastInteraction = localStorage.getItem(
      SESSION_LAST_INTERACTION
    );
    rumInfo.sessionCount = localStorage.getItem(SESSION_COUNT);
    rumInfo.sessionLandingPage = localStorage.getItem(SESSION_LANDING_PAGE);
    if (typeof performance === "object" && performance.getEntriesByType) {
      const _navPerfMetrics = performance.getEntriesByType(
        "navigation"
      )[0] as PerformanceNavigationTiming;
      const _firstPaintPerfMetrics = performance.getEntriesByType("paint")[0];
      const _firstContPaintPerfMetrics = performance.getEntriesByType(
        "paint"
      )[1];

      // memory raw
      if (performance["memory"]) {
        rumInfo.jsHeapSizeLimit = performance["memory"]["jsHeapSizeLimit"] || 0;
        rumInfo.totalJSHeapSize = performance["memory"]["totalJSHeapSize"] || 0;
        rumInfo.usedJSHeapSize = performance["memory"]["usedJSHeapSize"] || 0;
      } else {
        rumInfo.jsHeapSizeLimit = 0;
        rumInfo.totalJSHeapSize = 0;
        rumInfo.usedJSHeapSize = 0;
      }
      if (typeof _navPerfMetrics === "object") {
        // timing raw
        rumInfo.connectEnd = _navPerfMetrics.connectEnd;
        rumInfo.connectStart = _navPerfMetrics.connectStart;
        rumInfo.decodedBodySize = _navPerfMetrics.decodedBodySize;
        rumInfo.domComplete = _navPerfMetrics.domComplete;
        rumInfo.domContentLoadedEventEnd =
          _navPerfMetrics.domContentLoadedEventEnd;
        rumInfo.domContentLoadedEventStart =
          _navPerfMetrics.domContentLoadedEventStart;
        rumInfo.domInteractive = _navPerfMetrics.domInteractive;
        rumInfo.domainLookupEnd = _navPerfMetrics.domainLookupEnd;
        rumInfo.domainLookupStart = _navPerfMetrics.domainLookupStart;
        rumInfo.duration = _navPerfMetrics.duration;
        rumInfo.encodedBodySize = _navPerfMetrics.encodedBodySize;
        rumInfo.fetchStart = _navPerfMetrics.fetchStart;
        rumInfo.initiatorType = _navPerfMetrics.initiatorType;
        rumInfo.loadEventEnd = _navPerfMetrics.loadEventEnd;
        rumInfo.loadEventStart = _navPerfMetrics.loadEventStart;
        rumInfo.requestStart = _navPerfMetrics.requestStart;
        rumInfo.responseEnd = _navPerfMetrics.responseEnd;
        rumInfo.responseStart = _navPerfMetrics.responseStart;
        rumInfo.secureConnectionStart = _navPerfMetrics.secureConnectionStart;
        rumInfo.unloadEventEnd = _navPerfMetrics.unloadEventEnd;
        rumInfo.unloadEventStart = _navPerfMetrics.unloadEventStart;
        rumInfo.transferSize = _navPerfMetrics.transferSize;
        rumInfo.navigationType = _navPerfMetrics.type;
      }

      // paint time
      if (_firstPaintPerfMetrics) {
        rumInfo.firstPaint = _firstPaintPerfMetrics.startTime;
      } else {
        rumInfo.firstPaint = 0;
      }
      if (_firstContPaintPerfMetrics) {
        rumInfo.firstContentfulPaint = _firstContPaintPerfMetrics.startTime;
      } else {
        rumInfo.firstContentfulPaint = 0;
      }
      rumInfo.isClientSideNavigation = visitInfo.isClientSideNavigation;
    }

    const logPageViewsRum = localStorage.getItem(LOG_PAGE_VIEWS_RUM);
    let rumInfoArray = null;
    if (
      logPageViewsRum === null ||
      logPageViewsRum === "null" ||
      logPageViewsRum === "undefined" ||
      typeof logPageViewsRum === "undefined"
    ) {
      rumInfoArray = [];
    } else {
      rumInfoArray = JSON.parse(logPageViewsRum);
    }
    if (navigator === null || navigator.onLine === null || navigator.onLine) {
      rumInfoArray.push(rumInfo);
      try {
        if (typeof navigator !== "undefined" && navigator.sendBeacon) {
          navigator.sendBeacon(
            buildRumEndpoint(process.env.GRAPHQL_ENDPOINT),
            JSON.stringify(transformLogPageViewRum(rumInfo))
          );
        }
        await postLogPageViewRum(rumInfo);
      } catch (e) {
        console.log(e);
        if (window && typeof window.Sentry === `object`) {
          window.Sentry.captureException(e);
        }
      }
      if (hasLocalStorage()) {
        localStorage.removeItem(LOG_PAGE_VIEWS_RUM);
      }
    } else {
      rumInfoArray.push(rumInfo);
      if (hasLocalStorage()) {
        localStorage.setItem(LOG_PAGE_VIEWS_RUM, JSON.stringify(rumInfoArray));
      }
    }
    window.removeEventListener("beforeunload", logPageViewFn);
  }

  window.addEventListener("beforeunload", logPageViewFn, { once: true });
}

function makeId(size = 43): string {
  let text = "";
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-";

  for (let i = 0; i < size; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

function updateSession(): void {
  if (hasLocalStorage()) {
    const eventTime = Date.now();
    if (!localStorage.getItem(CURRENT_SESSION)) {
      localStorage.setItem(CURRENT_SESSION, "true");
      localStorage.setItem(SESSION_ID, makeId(10) + eventTime.toString());
      localStorage.setItem(SESSION_START_TIME, JSON.stringify(eventTime));
      localStorage.setItem(SESSION_LAST_INTERACTION, JSON.stringify(eventTime));
      localStorage.setItem(SESSION_COUNT, JSON.stringify(1));
      localStorage.setItem(SESSION_LANDING_PAGE, window.location.href);
    } else {
      const timeoutMilli = 30 * 60 * 1000;
      const lastInteractionTime = parseInt(
        localStorage.getItem(SESSION_LAST_INTERACTION)
      );
      if (eventTime - lastInteractionTime > timeoutMilli) {
        localStorage.setItem(SESSION_ID, makeId(10) + eventTime.toString());
        localStorage.setItem(SESSION_START_TIME, JSON.stringify(eventTime));
        localStorage.setItem(
          SESSION_LAST_INTERACTION,
          JSON.stringify(eventTime)
        );
        const sCount = parseInt(localStorage.getItem(SESSION_COUNT)) + 1;
        localStorage.setItem(SESSION_COUNT, JSON.stringify(sCount));
        localStorage.setItem(SESSION_LANDING_PAGE, window.location.href);
      } else {
        localStorage.setItem(
          SESSION_LAST_INTERACTION,
          JSON.stringify(eventTime)
        );
      }
    }
  }
}

function updatePreviousPath(): string {
  if (hasLocalStorage()) {
    let previousPath = localStorage.getItem(PREVIOUS_PATH);
    if (!previousPath) {
      previousPath = "landed";
    }
    localStorage.setItem(PREVIOUS_PATH, window.location.pathname);
    return previousPath;
  }
  return undefined;
}

function getTrackingInfo(event: Event, element: HTMLElement): any {
  const trackInfo: any = {};
  trackInfo.category = element.attributes.getNamedItem("tracking-category")
    ? element.attributes.getNamedItem("tracking-category").value
    : window.location.href;

  trackInfo.action = element.attributes.getNamedItem("tracking-action")
    ? element.attributes.getNamedItem("tracking-action").value
    : event.type;

  trackInfo.label = element.attributes.getNamedItem("tracking-label")
    ? element.attributes.getNamedItem("tracking-label").value
    : element.id;

  trackInfo.text = element.attributes.getNamedItem("tracking-text")
    ? element.attributes.getNamedItem("tracking-text").value
    : element.outerText;

  trackInfo.value = element.attributes.getNamedItem("tracking-value")
    ? element.attributes.getNamedItem("tracking-value").value
    : null;

  trackInfo.arbitrary1 = element.attributes.getNamedItem("tracking-a1")
    ? element.attributes.getNamedItem("tracking-a1").value
    : null;

  trackInfo.arbitrary2 = element.attributes.getNamedItem("tracking-a2")
    ? element.attributes.getNamedItem("tracking-a2").value
    : null;

  trackInfo.arbitrary3 = element.attributes.getNamedItem("tracking-a3")
    ? element.attributes.getNamedItem("tracking-a3").value
    : null;

  trackInfo.arbitrary4 = element.attributes.getNamedItem("tracking-a4")
    ? element.attributes.getNamedItem("tracking-a4").value
    : null;

  trackInfo.href =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname;

  return trackInfo;
}

function updateSessionAndLog(event: Event): void {
  updateSession();
  const target = event.target as HTMLElement;
  if (target.classList) {
    const trackInfo = getTrackingInfo(event, target);
    if (target.classList.contains("tracking")) {
      logUserInteraction({
        category: trackInfo.category,
        action: trackInfo.action,
        label: trackInfo.label,
        value: trackInfo.value,
        arbitrary1: trackInfo.text,
        arbitrary2: trackInfo.href
      });
    } else if (target.classList.contains("fantasy-tracking")) {
      logUserInteraction({
        category: trackInfo.category,
        action: trackInfo.action,
        label: trackInfo.label,
        value: trackInfo.value,
        arbitrary1: trackInfo.arbitrary1,
        arbitrary2: trackInfo.arbitrary2,
        arbitrary3: trackInfo.arbitrary3,
        arbitrary4: trackInfo.arbitrary4
      });
    }
  }
}

export function logCurrentResume(resumeID: string): void {
  if (hasLocalStorage()) {
    localStorage.setItem(AMPLIFY_CURRENT_RESUME_ID, resumeID);
  }
}

/**
 *
 * @param category -
 * @param action
 * @param label
 * @param value
 */

export async function logUserInteraction(params: {
  category?: string;
  action: string;
  label: string;
  value?: number;
  arbitrary1?: string;
  arbitrary2?: string;
  arbitrary3?: string;
  arbitrary4?: string;
}): Promise<any> {
  const __visitorHash =
    getStorageItem("__amplify____visitorHash")["data"] || "undefined";

  const __pageUrl = window.location.href || "undefined";

  const __userId = getLocalUserObject()?.id;

  const __sessionId = localStorage.getItem(SESSION_ID) || "undefined";
  const __unixTime = (Date.now() || 0) / 1000;

  const __resumeId =
    localStorage.getItem(AMPLIFY_CURRENT_RESUME_ID) || "undefined";

  const interactionInfo: any = {};

  interactionInfo.eventTestName = __testName;
  interactionInfo.visitorHash = __visitorHash;
  interactionInfo.pageUrl = __pageUrl;

  if (__userId) {
    interactionInfo.userId = __userId;
  }

  interactionInfo.sessionId = __sessionId;
  interactionInfo.unixTime = __unixTime;
  interactionInfo.resumeId = __resumeId;
  interactionInfo.userAgent =
    window.navigator.userAgent.toLowerCase() || "undefined";

  interactionInfo.eventCategory = params.category || "undefined";
  interactionInfo.eventAction = params.action || "undefined";
  interactionInfo.eventLabel = params.label || "undefined";
  interactionInfo.eventValue = params.value || 0;

  interactionInfo.arbitrary1 = params.arbitrary1 || "undefined";
  interactionInfo.arbitrary2 = params.arbitrary2 || "undefined";
  interactionInfo.arbitrary3 = params.arbitrary3 || "undefined";
  interactionInfo.arbitrary4 = params.arbitrary4 || "undefined";

  return await postLogInteraction(interactionInfo);
}
