import { ApolloClient, Observable } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import { navigate } from "gatsby";
import fetch from "isomorphic-fetch";

import possibleTypes from "../../../possibleTypes.json";

import { hasAuthorizationFailedErr, refreshToken } from "~lib/authentication";
import { RESUME_USER } from "~lib/keyNamesOfLocalStorage";
import { removeLocalStorageUser } from "~lib/localStorage";

const { createUploadLink } = require("apollo-upload-client");

const redirectForApolloError = () => {
  const tokenErrorAndForceLogoutState = { tokenError: true, forceLogout: true };
  navigate("/signin", {
    state: tokenErrorAndForceLogoutState
  });
};

const promiseToObservable = promise =>
  new Observable(subscriber => {
    promise.then(
      value => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      err => {
        removeLocalStorageUser();
        const domain = process.env.HOSTNAME || "resume.com";
        const jwtDomain = domain.replace("www.", "");
        const path = "/";
        document.cookie =
          "jwt=; expires=" +
          +new Date() +
          "; domain=" +
          `.${jwtDomain}` +
          "; path=" +
          path;
        redirectForApolloError();
      }
    );
    return subscriber; // this line can be removed, as per next comment
  });

// FragmentMatcher, HeuristicFragmentMatcher, and IntrospectionFragmentMatcher have all been removed.
// We recommend using the InMemoryCache’s possibleTypes option instead.
const cache = new InMemoryCache({ possibleTypes });

export const client = new ApolloClient({
  request: operation => {
    const { operationName, variables } = operation || {};
    // eslint-disable-next-line no-undef
    if (typeof Sentry === `object`) {
      Sentry.configureScope(scope => {
        scope.clear();
        scope.setExtra("operationName", operationName);
        Object.keys(variables || {}).forEach(key =>
          scope.setExtra(key, variables[key])
        );
      });
    }
  },
  onError: ({ graphQLErrors, networkError, operation, forward }) => {
    const { operationName } = operation || {};
    const isRegularUser = JSON.parse(localStorage.getItem(RESUME_USER))
      ?.accountEmail;
    if (graphQLErrors) {
      if (
        operationName !== "signInWithIndeed" &&
        hasAuthorizationFailedErr({ graphQLErrors })
      ) {
        if (isRegularUser) {
          return promiseToObservable(refreshToken()).flatMap(() =>
            forward(operation)
          );
        } else {
          redirectForApolloError();
        }
      }
    }
  },
  cache,
  link: createUploadLink({
    uri: process.env.GRAPHQL_ENDPOINT,
    credentials: "include"
  }),
  fetch
});
