export const MANAGE_SECTIONS = "Manage Sections";
export const GENERAL_TIPS = "General Tips";

export const PROFILE_SECTION = "profile";
export const EDUCATION_SECTION = "education";
export const SKILLS_SECTION = "skills";
export const EMPLOYMENT_SECTION = "employment";
export const LANGUAGES_SECTION = "languages";
export const SUMMARY_SECTION = "summary";
export const CUSTOM_SECTION = "customSection";
export const CERTIFICATES_SECTION = "certificates";
export const AWARDS_SECTION = "awards";
export const CONTACT_DETAILS_SECTION = "contactDetails";

export const DEFAULT_SECTIONS_ORDER = [
  SUMMARY_SECTION,
  EMPLOYMENT_SECTION,
  SKILLS_SECTION,
  EDUCATION_SECTION
];

export const ALL_SECTIONS_ORDER = [
  SUMMARY_SECTION,
  EMPLOYMENT_SECTION,
  SKILLS_SECTION,
  EDUCATION_SECTION,
  LANGUAGES_SECTION,
  CERTIFICATES_SECTION,
  AWARDS_SECTION
];

export const SECTIONS_HAVE_ITEMS = [EMPLOYMENT_SECTION, EDUCATION_SECTION];

export const DEFAULT_TEMPLATE_CODE = "rec1";

export const HEADER_HEIGHT = 70;

export const SHARE_WITH_INDEED_SOURCE_TYPE = {
  RESCOM: "RESUMECOM"
};

export const SECTION_HAS_EXAMPLES_SINGULAR_FORM = {
  [SUMMARY_SECTION]: "summary",
  [EMPLOYMENT_SECTION]: "experience",
  [SKILLS_SECTION]: "skill",
  [EDUCATION_SECTION]: "education",
  [LANGUAGES_SECTION]: "language"
};

export const EMPTY_RESUME = {
  id: "",
  title: "",
  template: "",
  createdDate: 0,
  updatedDate: 0,
  wordCount: 0,
  desiredTitle: "",
  contactDetails: {
    email: "",
    phone: "",
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    zip: "",
    country: "",
    state: "",
    jobCategory: "",
    __typename: "ContactDetails"
  },
  sections: [],
  sectionsOrder: ["summary", "skills", "education", "employment"],
  allSectionsOrder: [
    "summary",
    "skills",
    "education",
    "employment",
    "languages",
    "certificates",
    "awards"
  ],
  __typename: "Resume"
};

export const EMPTY_COVER_LETTER = {
  id: "",
  title: "",
  body: "",
  footer: "",
  template: "",
  creationDate: 0,
  updateDate: 0,
  contactDetails: {
    email: "",
    phone: "",
    firstName: "",
    lastName: "",
    jobCategory: ""
  }
};

export const ALL_MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

export const DOUBLE_DIGIT_DIVIDING_LINE = 10;

export const Z_INDEX = {
  defaultZIndex: "0",
  triggerZIndex: "100",
  toolTipZIndex: "700",
  drawerZIndex: "800",
  navBarZIndex: "900",
  modalZIndex: "1000"
};

export const SKIP_OR_SIGN_IN_FOLLOW_UP_OPERATION = {
  LOAD_TEMPLATE: "loadTemplate",
  UPLOAD_RESUME: "uploadResume"
};

export const AI_GENERATES_CONTENT = [
  "Prepared financial statements, managed budgeting and forecasting, conducted audits and reconciliations, processed accounts payable and accounts receivable, provided financial analysis and reporting.",
  "Managed office correspondence, scheduled appointments and meetings, handled travel arrangements, maintained office supplies and inventory, welcomed visitors and clients",
  "Developed and maintained software applications, collaborated in Agile development, diagnosed and addressed software defects, conducted code reviews, contributed to software documentation",
  "Developed and delivered lesson plans, managed classroom activities, assessed student progress, fostered a supportive learning environment, communicated with parents and colleagues",
  "Designed and reviewed engineering plans, conducted site inspections and surveys, managed project budgets and timelines, collaborated with multidisciplinary teams, prepared engineering reports and documentation",
  "Provided direct patient care, conducted thorough health assessments, responded effectively to emergencies, educated patients and their families on health conditions, administered various medical treatments and procedures.",
  "Provided excellent customer service, maintained a clean and organized workspace, memorized menu offerings, collaborated with the kitchen staff, processed payments and handled cash",
  "Safely operated and maintained commercial trucks, conducted pre-trip inspections, managed transportation documentation, efficiently navigated shipping routes, demonstrated strong communication skills while interacting with customers",
  "Operated heavy machinery and power tools, interpreted blueprints and schematics, assisted in site preparation, complied with all relevant safety regulations.",
  "Maintained clean and organized workstations, greeted and interacted with customers, managed cash transactions, prepared and served beverages, restocked inventory, adhered to food handling and sanitation guidelines."
];

export const AI_WRITER_HELP_TIPS =
  "This work experience has been generated using information provided by you, please review it to make sure it is accurate and appropriate before sharing it with others. You are solely responsible for the content.";

export const ANIMATION_DELAY_START_TIME = {
  AI_WRITER_ANIMATION_MODAL: 500,
  BUILDER_ANIMATION: 600,
  EXPLORE_MORE_CARD_NUDGE_ANIMATION: 500,
  EXPLORE_MORE_CARD_CONTENT_ANIMATION: 400,
  POP_OVER_ANIMATION_DEFAULT: 100,
  POP_OVER_ANIMATION_SIDE_BAR: 150
};

export const ANIMATION_DURATION_TIME = {
  NUDGE_ANIMATION: 10000,
  MESSAGE_DEFAULT: 3000,
  MESSAGE_COMMON: 5000
};

export const ANIMATION_DELAY_CLOSE_TIME = {
  EXPLORE_MORE_CARD_NUDGE_ANIMATION: 1200,
  POP_OVER_ANIMATION_DEFAULT: 100,
  POP_OVER_ANIMATION_SIDE_BAR: 150
};

export const DISCLAIMER_TEXT =
  "The information on this site is provided as a courtesy. Indeed is not a career or legal advisor and does not guarantee job interviews or offers.";

export const MAXIMUM_RESUME_LIMIT = 50;
export const MAXIMUM_COVER_LETTER_LIMIT = 50;
export const REBRANDING_MAXIMUM_RESUME_LIMIT = 5;

export const MOVE_GUEST_RESUME_TO_ACCOUNT_ERROR =
  "moveGuestResumesToAccountError";
