import React, { useEffect, useState, FC, useRef } from "react";

import * as styles from "./styles.module.scss";

import CustomButton from "~components/common/customButton";
import AIWriterIcon from "~images/textareaNew/wand-rays.svg";
import useOnClickOutside from "~lib/hooks/useOnClickOutside";
import { DISAPPEAR_AI_TAG } from "~lib/keyNamesOfLocalStorage";
import { hasLocalStorage } from "~lib/localStorage";
import { logUserInteraction } from "~src/lib/log-page-views";

type Props = {
  onAIWriterBtnClick: () => void;
};

const setLocalStorageTimesDisappearOfAITag = () => {
  if (hasLocalStorage()) {
    localStorage.setItem(DISAPPEAR_AI_TAG, "true");
  }
};

const AIWriterTag: FC<Props> = ({ onAIWriterBtnClick }) => {
  const [showAIWriterTagToast, setShowAIWriterTagToast] = useState(
    !localStorage.getItem(DISAPPEAR_AI_TAG)
  );

  const aiWriterTagToastRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      setShowAIWriterTagToast(false);
    }, 5000);
    setLocalStorageTimesDisappearOfAITag();
  }, []);

  useOnClickOutside(aiWriterTagToastRef, () => setShowAIWriterTagToast(false));

  return (
    <div className={styles.aiWriterTagContainer}>
      <CustomButton
        className={styles.modalSubTagLabel}
        onClick={() => {
          onAIWriterBtnClick();
          logUserInteraction({
            category: "tweakify",
            label: "ai-writer",
            action: "click",
            arbitrary2: "experience-editor",
            arbitrary3: "low-res"
          });
        }}
      >
        <AIWriterIcon />
        AI Writer
      </CustomButton>
      {showAIWriterTagToast && (
        <div ref={aiWriterTagToastRef} className={styles.aiWriterTagToast}>
          Use your existing resume to create AI generated work experience
          <span className={styles.aiWriterTagTriangle} />
        </div>
      )}
    </div>
  );
};

export default AIWriterTag;
